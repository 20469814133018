import React from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { constants } from "../../redux/constants";
import { updateShopObject } from "../../redux/actions/shopActions";
import MainCarouselCard from "../MainCarousel/MainCarouselCard";
import "./FeatureSlider.scss";
import { goTo } from "../../utilities/utils";
import { useI18next } from "gatsby-plugin-react-i18next";

interface FeatureSliderProps {
  currentIndex: number;
}

const FeatureSlider: React.FC<FeatureSliderProps> = (
  props: FeatureSliderProps
) => {
  const dispatch = useDispatch();

  const setCountry = (index) => {
    switch (index) {
      case 0:
      case 1:
        window.localStorage.setItem("country", constants.CANADA);
        dispatch(updateShopObject({ country: constants.CANADA }) as any);
        dispatch(updateShopObject({ currency: constants.CANADA_CURRENCY }) as any);
        break;
      case 2:
        window.localStorage.setItem("country", constants.USA);
        dispatch(updateShopObject({ country: constants.USA }) as any);
        dispatch(updateShopObject({ currency: constants.USA_CURRENCY }) as any);
        break;
    }
  };

  const { language } = useI18next();

  const getImageLanguage = () => {
    if (language === "en") {
      return "/images/mc/01-03-23/en.png";
    } else if (language === "fr") {
      return "/images/mc/01-03-23/fr.png";
    } else if (language === "sp") {
      return "/images/mc/01-03-23/sp.png";
    }
  };

  return (
    <Carousel
      className="feature-slider-carousel"
      indicators={null}
      interval={8000}
      nextIcon={null}
      prevIcon={null}
    >
      {props.currentIndex === 0 && [
        <Carousel.Item key={0}>
          <MainCarouselCard
            imageUrl="/images/mc/mc_canada_plans.png"
            titleText="Canadian Plans"
            buttonText="Visit"
            style={{ margin: "30px" }}
            onClick={() => {
              setCountry(1);
              goTo("/plans");
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={1}>
          <MainCarouselCard
            imageUrl="/images/mc/mc_american_plans.png"
            titleText="American Plans"
            buttonText="Visit"
            style={{ margin: "30px" }}
            onClick={() => {
              setCountry(2);
              goTo("/plans");
            }}
          />
        </Carousel.Item>,
      ]}
      {props.currentIndex === 1 && [
        <Carousel.Item key={2}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_maximize_your_data_${language}.png`}
            titleText="New Plans Available!"
            onClick={() => {
              goTo("/plans");
            }}
            style={{ margin: "30px" }}
            buttonText="Get It Now!"
          />
        </Carousel.Item>,
        <Carousel.Item key={3}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_esimdeal_${language}.png`}
            titleText="Digital SIM Card"
            showConnectInAFlash
            onClick={() => {
              goTo("https://esim.gophonebox.com/");
            }}
            style={{ margin: "30px" }}
            buttonText="Check It Out!"
          />
        </Carousel.Item>,
        <Carousel.Item key={4}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_new_travel_plan_${language}.png`}
            style={{ margin: "30px" }}
            titleText="Prepaid Plans"
            showPrepaidPlans
            onClick={() => {
              goTo("https://blog.gophonebox.com/meet-phonebox-usa-the-best-telecom-service-now-in-america")
            }}
            buttonText="Discover!"
          />
        </Carousel.Item>,
      ]}
      {props.currentIndex == 2 && [
        <Carousel.Item key={5}>
          <MainCarouselCard
            imageUrl="/images/mc/mc_bay_bridge_green.png"
            style={{ margin: "30px" }}
            titleText="New PhoneBox US"
            subtitleText="Find more information on our US services!"
            buttonText="Know More"
            onClick={() => {
              goTo("https://blog.gophonebox.com/meet-phonebox-usa-the-best-telecom-service-now-in-america");
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={6}>
          <MainCarouselCard
            imageUrl="/images/mc/mc_pb_app.png"
            titleText="PhoneBox US App"
            style={{ margin: "30px" }}
            subtitleText="Managing your account has never been easier!"
            buttonText="Download Now"
            onClick={() => {
              goTo("https://linktr.ee/phoneboxusa");
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={7}>
          <MainCarouselCard
            style={{ margin: "30px" }}
            imageUrl="/images/mc/mc_esim.png"
            titleText="eSIM for US!"
            subtitleText="Are you ready for eSIM?"
            buttonText="Activate it now!"
            onClick={() => {
              setCountry(2);
              goTo("/activation");
            }}
          />
        </Carousel.Item>,
      ]}
    </Carousel>
  );
};

export default FeatureSlider;
