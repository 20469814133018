import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface PriceDisplayProps {
  price: number;
  timeUnit: string;
  data: number;
}

const BestOffersTextOverlay: React.FC<PriceDisplayProps> = (props: PriceDisplayProps) => {
  return (

    <Container>
      <Title>
        <Trans>What's Trending?</Trans>
      </Title>
      <Information>
        <InformationData>{props.data}GB</InformationData>
        <InformationLine>|</InformationLine>
        <PriceContainer>
          <PriceWrapper>
            <PriceCurrencySymbol>$</PriceCurrencySymbol>
            <PriceCost>{props.price}</PriceCost>
          </PriceWrapper>
          <PriceTimeUnit>
            <Trans>{props.timeUnit}</Trans>
          </PriceTimeUnit>
        </PriceContainer>
      </Information>
      <MoreInformation>
        <Trans>Unlimited international calls & international texts</Trans>
      </MoreInformation>
    </Container>
  );
};

const PriceContainer = styled.div`
  display: flex;
  align-items: end;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: start;
`;

const PriceCurrencySymbol = styled.div`
  font: normal normal bold 10px/30px Poppins;
  color: #fff;
`;

const PriceCost = styled.div`
  font: normal normal bold 30px Poppins;
  color: #fff
`;


const PriceTimeUnit = styled.div`
  font: normal normal normal 10px Poppins;
  color: #fff;
  margin-bottom: 10px;
`;

const Container = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  // border: 1px solid black; 
  width: 273px;
  height: 185.1px;
  color: white;
  padding: 15px;
`;

const Title = styled.div`
  display: inline-block;
  border: 2px solid white;
  padding: 5px 15px;
  margin-bottom: 12px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 6px;
    font-size: 15px;
  }
`;

const InformationData = styled.div`
  font: normal normal bold 30px Poppins;
`;

const InformationLine = styled.div`
  font: normal normal normal 30px Poppins;
`;

const Information = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 20px;
  margin-bottom: 10px;

  @media (min-width: 768px) and (max-width: 991px) {
    gap: 10px;
    margin-bottom: 0;
  }
`;

const MoreInformation = styled.p`
  color: white;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
  }
`;
export default BestOffersTextOverlay;
