import React, { useEffect, useState } from "react";
import AOS from "aos";
import PageLayout from "../components/PageLayout";
import styled from "styled-components";
import { Container, Image } from "react-bootstrap";
import PlanExplainBox from "../components/PlanExplainBox";
import ChoosePhoneBoxSlideShow from "../components/ChoosePhoneboxSlideShow";
import LoadingSpinner from "../components/LoadingSpinner";
import { useSelector, useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";
import { getCountryCodes } from "../redux/actions/getCountriesApiActions";
import { getCurrentCountry } from "../redux/actions/getCurrentCountry";
import { RootState } from "../redux/reducers/rootReducer";
import BestOffers from "../components/BestOffers";
import MainCarousel from "../components/MainCarousel/MainCarousel";
import { Trans } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import ClickButton from "../components/ClickButton";
import { constants } from "../redux/constants";
import { goTo } from "../utilities/utils";
import FeatureSlider from "../components/FeatureSlider/FeatureSlider";
import { updateShopObject } from "../redux/actions/shopActions";
import { Crisp } from "crisp-sdk-web";


export default function index() {
  const phoneImage = "/images/home/phone.png";
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState(false);

  let shopDetails = useSelector((state: RootState) => {
    return state.shop;
  });

  const getIndexFromCountry = (country) => {
    if (country === 'CA' || country === constants.CANADA) {
      return 1;
    } else if (country === 'US' || country === constants.USA) {
      return 2;
    } else {
      return 0;
    }
  }

  let defaultIndex = getIndexFromCountry(shopDetails.country);
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState("0");

  useEffect(() => {
    AOS.init({ once: true });
    // Crisp.configure("bc0607c9-862a-4d23-8455-ce801f9b3639");
    dispatch(saveRouteDetails("index") as any);
    dispatch(getCountryCodes() as any);
    // we need it due to rogers plans rollbacks..
    // no longer need any of these because it should default to eSIM.
    const getCountry = async () => {
      const countryResponse = await getCurrentCountry();
      if (!countryResponse || !countryResponse.data) {
        setCurrentIndex(1);
        setLoading(false);
        return;
      }
      const country = countryResponse.data.country_code;
      setCurrentIndex(getIndexFromCountry(country));
      const constantsCountry = country === "US" ? constants.USA : constants.CANADA;
      dispatch(updateShopObject({ country: constantsCountry }) as any);
      setLoading(false);
    }
    if (!shopDetails.country) {
      getCountry().catch((error) => {
        console.log(error);
        setCurrentIndex(1);
        setLoading(false);
      });
    } else {
      setCurrentIndex(getIndexFromCountry(shopDetails.country));
      // setCurrentIndex(0);
      setLoading(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!loading) {
      setOpacity("1");
    }
  }, [loading]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 500) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);


  if (loading === true) {
    return (
      <LoadingSpinner />
    );
  }

  return (
    <>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TJKD8FQ" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
      <PageLayout opacity={opacity} transition={"opacity 1s"} showPartnerNav>
        <title>Home</title>
        <MainCarousel setIndex={setCurrentIndex} currentIndex={currentIndex} />

        {mobile && (
          <FeatureSection>
            <Title data-aos="fade-in">
              <Trans>Featuring</Trans>
            </Title>
            <FeatureSlider currentIndex={currentIndex} />
          </FeatureSection>
        )}

        <BestOffersSection>
          <Container>
            <Title data-aos="fade-in">
              <Trans>The Best Offers for You</Trans>
            </Title>
            <BestOffers currentIndex={currentIndex}></BestOffers>
          </Container>
        </BestOffersSection>

        <div>
          <PlanExplainBox />
        </div>
        <WhyChoosePhoneBoxSection>
          <div data-aos="fade-in">
            <Title>
              <Trans>Why Choose PhoneBox?</Trans>
            </Title>
          </div>
          <ChoosePhoneBoxSlideShow />
        </WhyChoosePhoneBoxSection>
        <AlreadyHaveASIMCardSection>
          <Container>
            <Title data-aos="fade-in">
              <Trans>Already have a SIM card?</Trans>
            </Title>

            {mobile ? (
              <>
                <Mobile>
                  <Image
                    width="70px"
                    style={{ paddingTop: "10px" }}
                    src={phoneImage}
                    alt="phone image"
                  />
                  <ClickButton
                    text="Activate Now"
                    color={"red"}
                    buttonLength={""}
                    length={"long"}
                    onClick={() => {
                      goTo("/activation");
                    }}
                  />
                </Mobile>
              </>
            ) : (
              <div className="text-center my-5">
                <Image width="150px" src={phoneImage} alt="phone image" />
                <ClickButton
                  text="Activate Now"
                  color={"red"}
                  buttonLength={""}
                  length={"long"}
                  onClick={() => {
                    goTo("/activation");
                  }}
                />
              </div>
            )}
          </Container>
        </AlreadyHaveASIMCardSection>
      </PageLayout>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const Mobile = styled.div`
  text-align: center;
  margin-top: 1.5rem;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 22px;
  }
`;

const BestOffersSection = styled.div`
  padding: 100px 20px 0px 20px;
  background-color: #fff;
  color: black;
`;

const WhyChoosePhoneBoxSection = styled.div`
  padding: 100px 20px;
  background-color: #fff;
`;

const AlreadyHaveASIMCardSection = styled.div`
  padding: 0px 20px 0px 20px;
  background-color: #fff;
  clear: both;
`;

const FeatureSection = styled.div`
  padding: 60px 20px 0px 20px;
  background-color: #fff;
  color: black;
`;

export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `
      }}/>
    </>
  )
}