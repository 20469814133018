import React, { useEffect, useState } from "react";
import "./MainCarouselCard.scss";
import { Trans } from "react-i18next";
import { Button, Card } from "react-bootstrap";
import { useI18next } from "gatsby-plugin-react-i18next";


interface MarinCarouselCardProps {
  imageUrl: string;
  titleText: string;
  buttonText?: string;
  style?: any;
  onClick?: React.MouseEventHandler;
  fontSize?: string;
  showDeals?: boolean;
  showNewPlans?: boolean;
  subtitleText?: string;
  showConnectInAFlash?: boolean;
  showPrepaidPlans?: boolean;
}

const MainCarouselCard: React.FC<MarinCarouselCardProps> = (
  props: MarinCarouselCardProps
) => {
  const { language } = useI18next();

  const [isMobile, setIsMobile] = useState(false);
  const [pageWidth, setPageWidth] = useState(0);

  const [yellowFontStyle, setYellowFontStyle] = useState("normal normal bold 26px/26px Poppins");
  const [whiteFontStyle, setWhitFontStyle] = useState("normal normal bold 10px/10px Poppins");

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      setPageWidth(window.innerWidth);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  const notEnglish = language !== "en";

  return (
    <>
      <Card className="mc-card" style={props.style}>
        <Card.Img src={props.imageUrl}/>
        <Card.ImgOverlay className="mc-card-image-overlay">
          <Card.Title
            className="mc-card-title"
            style={{ fontSize: props.fontSize, whiteSpace: 'pre-line' }}
          >
            <Trans>{props.titleText}</Trans>
          </Card.Title>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%'
          }}>
            {props.subtitleText && (
              <p style={{ fontWeight: 500, paddingBottom: !props.buttonText ? '22px' : '8px', paddingTop: '8px' }} className="m-0 px-3 text-white fs-6">
                <Trans>{props.subtitleText}</Trans>
              </p>
            )}
            {!props.subtitleText && (
              <div></div>
            )}
            {props.buttonText && (
              <Button className="mc-card-button" onClick={props.onClick} style={{ whiteSpace: (props.buttonText.length > 11 && language === 'en') ? 'nowrap' : 'normal' }}>
                <Trans>{props.buttonText}</Trans>
              </Button>
            )}
          </div>
        </Card.ImgOverlay>
      </Card>
    </>
  );
};

export default MainCarouselCard;
