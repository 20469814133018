import React, { useEffect } from "react";
import { useState } from "react";
import "./MainCarousel.scss";
import { constants } from "../../redux/constants";
import { updateShopObject } from "../../redux/actions/shopActions";
import { useDispatch } from "react-redux";
import { CardGroup, Carousel, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MainCarouselCard from "./MainCarouselCard";
import MainCarouselTitle from "./MainCarouselTitle";
import MainCarouselFancyTitle from "./MainCarouselFancyTitle";
import MainCarouselTitleDescription from "./MainCarouselTitleDescription";
import MainCarouselSubtitle from "./MainCarouselSubtitle";
import MainCarouselSubtitleDescription from "./MainCarouselSubtitleDescription";
import MainCarouselPriceBox from "./MainCarouselPriceBox";
import MainCarouselPlansButton from "./MainCarouselPlansButton";
import { goTo } from "../../utilities/utils";
import styled from "styled-components";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Trans, useTranslation } from "react-i18next";


interface MainCarouselProps {
  currentIndex: number;
  setIndex: Function;
}

const MainCarousel: React.FC<MainCarouselProps> = (
  props: MainCarouselProps
) => {
  const dispatch = useDispatch();
  const handleSelect = (selectedIndex) => {
    setCountry(selectedIndex);
  };

  const { language } = useI18next();
  const { t } = useTranslation();
  const isFrench = language === "fr";
  const isEnglish = language === "en";

  const setCountry = (index) => {
    switch (index) {
      case 0:
      case 1:
        window.localStorage.setItem("country", constants.CANADA);
        dispatch(updateShopObject({ country: constants.CANADA }) as any);
        dispatch(updateShopObject({ currency: constants.CANADA_CURRENCY }) as any);
        break;
      case 2:
        window.localStorage.setItem("country", constants.USA);
        dispatch(updateShopObject({ country: constants.USA }) as any);
        dispatch(updateShopObject({ currency: constants.USA_CURRENCY }) as any);
        break;
    }
    props.setIndex(index);
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 992) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const nextIcon = () => {
    return mobile ? null : (
      <span className="mc-indicator">
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </span>
    );
  };

  const prevIcon = () => {
    return mobile ? null : (
      <span className="mc-indicator">
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </span>
    );
  };

  const getImageLanguage = () => {
    if (language === "en") {
      return "/images/mc/01-03-23/en.png";
    } else if (language === "fr") {
      return "/images/mc/01-03-23/fr.png";
    } else if (language === "sp") {
      return "/images/mc/01-03-23/sp.png";
    }
  };

  return (
    <>
      <Carousel
        nextIcon={nextIcon()}
        prevIcon={prevIcon()}
        interval={null}
        className="mc"
        activeIndex={props.currentIndex}
        indicators={mobile}
        onSelect={handleSelect}
      >
        <Carousel.Item className="mc-item mc-image-phonebox">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={5}>
                <MainCarouselTitle text="PhoneBox" />
                <MainCarouselTitleDescription
                  text="Whatever the purpose of your stay or the duration, all needs are covered by hero plans, "
                  textBold="tailor-made for all budgets!"
                />
              </Col>
              <Col sm={7} lg={7}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl="/images/mc/mc_canada_plans.png"
                    titleText="Canadian Plans"
                    buttonText="Visit"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setCountry(1);
                      goTo("/plans");
                    }}
                  />
                  <MainCarouselCard
                    imageUrl="/images/mc/mc_american_plans.png"
                    titleText="American Plans"
                    buttonText="Visit"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setCountry(2);
                      goTo("/plans");
                    }}
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item className="mc-item mc-image-canada">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={4}>
                <MainCarouselTitle text="Canada" />
                <MainCarouselSubtitle text="Explore True North" />
                <MainCarouselSubtitleDescription text="Fastest Network with Affordable Plans" />
                <MainCarouselPriceBox text="Plans From" price={9} />
                <MainCarouselPlansButton
                  className="mc-hide-content-mobile"
                  text="See Plans"
                  onClick={() => {
                    setCountry(1);
                    goTo("/plans");
                  }}
                />
              </Col>
              <Col sm={7} lg={8}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl={`/images/mc/mc_maximize_your_data_${language}.png`}
                    titleText="New Plans Available!"
                    onClick={() => {
                      goTo("/plans");
                    }}
                    buttonText="Get It Now!"
                  />
                  <MainCarouselCard
                    showDeals={true}
                    imageUrl={`/images/mc/mc_esimdeal_${language}.png`}
                    titleText="Digital SIM Card"
                    showConnectInAFlash
                    onClick={() => {
                      goTo("https://esim.gophonebox.com");
                    }}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                    buttonText="Check It Out!"
                  />
                  <MainCarouselCard
                    imageUrl={`/images/mc/mc_new_travel_plan_${language}.png`}
                    titleText="Prepaid Plans"
                    showPrepaidPlans
                    onClick={() => {
                      goTo("/plans");
                    }}
                    buttonText="Discover!"
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item className="mc-item mc-image-usa">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={4}>
                <MainCarouselTitle text="USA" />
                <MainCarouselSubtitle text="The best network" />
                <MainCarouselSubtitleDescription text="No worries about overages with prepaid plan" />
                <MainCarouselPriceBox text="Plans From" price={25} />
                <MainCarouselPlansButton
                  className="mc-hide-content-mobile"
                  text="See Plans"
                  onClick={() => {
                    setCountry(2);
                    goTo("/plans");
                  }}
                />
              </Col>
              <Col sm={7} lg={8}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl="/images/mc/mc_bay_bridge_green.png"
                    titleText="New PhoneBox US"
                    subtitleText="Find more information on our US services!"
                    buttonText="Know More"
                    onClick={() => {
                      goTo("https://blog.gophonebox.com/meet-phonebox-usa-the-best-telecom-service-now-in-america");
                    }}
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  />
                  <MainCarouselCard
                    imageUrl="/images/mc/mc_pb_app.png"
                    titleText="PhoneBox US App"
                    style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: "rgba(0,0,0,0)" }}
                    subtitleText="Managing your account has never been easier!"
                    buttonText="Download Now"
                    onClick={() => {
                      goTo("https://linktr.ee/phoneboxusa");
                    }}
                  />
                  <MainCarouselCard
                    imageUrl="/images/mc/mc_esim.png"
                    titleText="eSIM for US!"
                    subtitleText="Are you ready for eSIM?"
                    buttonText="Activate it now!"
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                    onClick={() => {
                      setCountry(2);
                      goTo("/activation");
                    }}
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default MainCarousel;

const OurButton = styled.div<{ color: string, width: string }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${props => props.color === '' ? '#fff' : props.color};
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  color: #fff;
  background-color: ${props => props.color ? props.color : 'rgba(0,0,0,0)'};
  width: ${props => props.width};
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  font-weight: 600;
  cursor: pointer;
`